import React from 'react'
import { useTranslation } from 'react-i18next';
import CardContact from '../cards/CardContact';

export default function ContactList({
    contacts = [],
    buttons,
    mergeContactExtras,
    changeBlocklistStatusContact
}) {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <p className="bp-c-cloud bp-fs-7 mv3">{`${contacts.length} contato${contacts.length != 1 ? "s" : ""}`}</p>
            {contacts.map(contact =>
                <CardContact
                    key={contact.identity}
                    buttons={buttons}
                    mergeContactExtras={mergeContactExtras}
                    changeBlocklistStatusContact={changeBlocklistStatusContact}
                    contact={contact}
                    {...contact}
                />
            )}
        </div>
    )
}
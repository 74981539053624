import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getApplicationDataAsync } from '../../services/application-service';
import { getLoggedUserAsync } from '../../services/user-service';

import Card from '../../components/Card';
import Header from '../Example/components/Header';
import Input from '../../components/Input/Input';

const Settings = () => {
    const [appInfo, setAppInfo] = useState({});
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState('');
    const { t, i18n } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        getAppDataAsync();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // console.log(appInfo, user, language);
    }, [appInfo, user, language]);

    const getAppDataAsync = async () => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);

        // console.log("appInfo", app);

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);
        // console.log("loggedUser", loggedUser);

        setLanguage(i18n.language);
    };

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header title={t('menu.goBack')} onClick={() => history.push('/')} />
            <div className="flex">
                <div className="w-100 m0 mb3 mb4-l mt4">
                    <Card className="min-h-18">
                        <div className="overflow-auto">
                            <h4 className="f4 mt0 mb3">
                                {t('title.systemInfo')}
                            </h4>

                            <p>Teste</p>

                            <Input 
                            
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

Settings.propTypes = {
    // onClick: PropTypes.func
};

export default Settings;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import { useSharkPlugins } from '../../../../contexts/SharkPluginsProvider';

const BLANK = '_blank';

const Header = ({ title, icon, openSidebar }) => {
    const { t } = useTranslation();

    const { sendAction } = useSharkPlugins()

    const renderOptions = () => (
        <div className="ml3 flex flex-row" style={{ gap: 20 }}>
            <Button
                text={t('button.document')}
                icon="link"
                variant="ghost"
                onClick={() => {
                    window.open("https://docs.sharkdev.com.br/blip/blocklist", BLANK)
                    sendAction({
                        type: "event",
                        action: "visitDocumentation",
                        value: 1
                    })
                }}
                dataTestId="repository-link"
            />

            <Button
                text={"Bloquear um contato"}
                icon="lock"
                variant="delete"
                dataTestId="repository-link"
                onClick={() => openSidebar("sb-left")}
            />
        </div>
    );

    return (
        <PageHeader
            title={title}
            icon={icon}
            relatedOptions={renderOptions()}
        />
    );
};

Header.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export default Header;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import IMPContainer from '../../constants/iframe-message-proxy-container';

import { showToast, startLoading, stopLoading } from '../../services/common-service'

import settings from '../../config';

import Header from './components/Header';
import api from '../../factory/api';
import { getApplicationDataAsync } from '../../services/application-service';
import { getLoggedUserAsync } from '../../services/user-service';
import CardInstance from '../../components/cards/CardInstance';
import { generateAuthorizationToken } from '../../services/utils';
import CardConnect from '../../components/cards/CardConnect';
import CardApresentation from '../../components/cards/CardApresentation';
import Copyright from '../../components/Copyright';
import CardToken from '../../components/CardToken';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import ErrorLoad from '../../components/ErrorLoad';
import ContactList from '../../components/ContactList';
import { IframeMessageProxy } from 'iframe-message-proxy';
import Button from '../../components/Button';
import Sidebar from '../../components/Sidebar';

import { useSharkPlugins } from "../../contexts/SharkPluginsProvider"
import moment from 'moment';

import "./styles.scss"
import Modal from '../../components/Modal';
import Input from '../../components/Input/Input';

const PAGE_ICON = 'lock';
const BLANK = '_blank';

const Home = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { sendAction } = useSharkPlugins()

    const [appInfo, setAppInfo] = useState({});
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState('');

    const [isLoading, setIsLoading] = useState(true)

    const [instance, setInstance] = useState(null)

    const [error, setError] = useState(null)

    // const { data } = useFetch(`/instances`);

    // const [storageName, setStorageName] = useState()
    let storageName = undefined;

    const [blockedContacts, setBlockedContacts] = useState([])

    const [openModal, setOpenModal] = useState()

    useEffect(() => {
        // if (appInfo != {} && user != {})
        if (appInfo?.tenantId && user?.culture) {
            // setStorageName()
            storageName = `blockedContacts-${appInfo.tenantId}`
            fetchContacts()
        }
    }, [appInfo, user])

    useEffect(() => {
        getAppDataAsync()
    }, [])

    useEffect(() => {
        let interval;
        if (isLoading && storageName) {
            interval = setInterval(() => {
                let blockeds = JSON.parse(localStorage.getItem(storageName) || "[]")
                if (blockeds.length > blockedContacts.length)
                    setBlockedContacts(blockeds)
            }, 1000)
        }

        return () => interval ? clearInterval(interval) : null
    }, [isLoading, storageName])

    const handleNavigation = useCallback(
        (path, params = {}) => {
            history.push(path, params);
        },
        [history]
    );

    const getAppDataAsync = async () => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);

        setLanguage(i18n.language);
    }

    const getContacts = async ({
        skip = 0,
        take = 100,
        filter
    }) => {
        let query = [
            skip != undefined ? `$skip=${skip}` : undefined,
            `$take=${take}`,
            filter ? `$filter=${filter}` : undefined
        ].filter(item => item)

        const result = await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.GET,
                    uri: `/contacts?${query.join("&")}`
                },
                // timeout: 9999999999999999999
            }
        });

        const { response } = result

        if (!response?.items)
            throw "Encontramos um problema ao carregar seus contatos."

        return response
    }

    const getTotalContacts = async () => {
        let { total } = await getContacts({ take: 1, filter: `(lastmessagedate%20le%20datetimeoffset'${moment().toISOString()}')` })
        return total
    }

    const fetchContacts = async () => {
        try {
            setIsLoading(() => true)
            showToast({
                type: BlipPortalToastTypes.INFO,
                message: "Estamos carregando os seus contatos. Isto pode demorar um pouco! Outra mensagem será exibida quando o carregamento for finalizado."
            });

            let total = await getTotalContacts()

            // console.log("total", total)

            let items = []

            let newBlockedContacts = blockedContacts

            let take = 10000
            for (let skip = 0; skip <= total; skip += take) {
                let response = await getContacts({ skip, take })

                let blockeds = response.items.filter((item) => item?.extras?.blocklistStatus == "userBlocked")

                items = [
                    ...items,
                    ...blockeds
                ]

                // console.log(`${skip + take} / ${total}`, items)

                for (let i = 0; i < items.length; i++) {
                    let index = newBlockedContacts.findIndex(contact => contact.identity == items[i].identity)

                    if (index >= 0)
                        newBlockedContacts[index] = items[i]
                    else
                        newBlockedContacts.push(items[i])
                }

                if (blockeds.length > 0) {
                    setBlockedContacts(() => newBlockedContacts)
                    localStorage.setItem(storageName, JSON.stringify(newBlockedContacts))
                }
            }

            setBlockedContacts(() => newBlockedContacts)
            localStorage.setItem(storageName, JSON.stringify(newBlockedContacts))

            sendAction({
                type: "information",
                action: "contactQuantity",
                value: newBlockedContacts.length
            })
        } catch (error) {
            console.error(error)
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: "Encontramos um problema ao carregar seus contatos."
            });
        } finally {
            setIsLoading(() => false)
            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: "Seus contatos foram carregados!"
            });

            // setIsLoading(() => false)
            // stopLoading()
        }
    }

    const mergeContactExtras = async (contact, extras, blocked) => {
        try {
            startLoading()

            let { name, identity } = contact

            const { response, ...rest } = await IframeMessageProxy.sendMessage({
                action: IMPContainer.Actions.SEND_COMMAND,
                content: {
                    command: {
                        method: IMPContainer.CommandMethods.MERGE,
                        uri: "/contacts",
                        type: "application/vnd.lime.contact+json",
                        name,
                        resource: {
                            identity,
                            extras
                        }
                    }
                }
            });

            console.log(response, contact, blocked)

            let newBlockedContacts = blocked == undefined ?
                blockedContacts.map(cont => (contact.identity == identity ? contact : cont)) :
                blocked ?
                    [...blockedContacts, contact] :
                    blockedContacts.filter(contact => contact.identity != identity)

            setBlockedContacts(newBlockedContacts)
            localStorage.setItem(storageName, JSON.stringify(newBlockedContacts))

            sendAction({
                type: "information",
                action: "contactQuantity",
                value: newBlockedContacts.length
            })
        } catch (error) {
            console.error(error)
        } finally {
            stopLoading()
        }
    }

    const changeBlocklistStatusContact = async (contact, blocked) => {
        try {
            if (blocked != true && blocked != false)
                throw "Não foi informado se será bloqueado ou desbloqueado"

            await mergeContactExtras(contact, { blocklistStatus: blocked == true ? "userBlocked" : "userUnblocked" }, blocked)
        } catch (error) {
            console.error(error)
        }
    }

    const generatePossiblePhones = (phone) => {
        phone = phone.replace(/\D/g, '')

        let versions = [
            // { useDDI: false, useNinth: true },
            // { useDDI: false, useNinth: false },
            { useDDI: true, useNinth: true },
            { useDDI: true, useNinth: false },
        ]

        let phones = versions.map(({ useDDI, useNinth }) => {
            let { ddi = "55", ddd, number } = {}

            if (phone.length == 10) { // Sem DDI e sem 9
                ddd = phone.substring(0, 2)
                number = phone.slice(2)
            } else if (phone.length == 11) { // Sem DDI e com 9
                ddd = phone.substring(0, 2)
                number = phone.slice(3)
            } else if (phone.length == 12) { // Com DDI e sem 9
                ddi = phone.substring(0, 2)
                ddd = phone.substring(2, 4)
                number = phone.slice(4)
            } else if (phone.length == 13) { // Com DDI e com 9
                ddi = phone.substring(0, 2)
                ddd = phone.substring(2, 4)
                number = phone.slice(5)
            }

            let newPhone = ""

            if (useDDI) newPhone += ddi
            newPhone += ddd
            if (useNinth) newPhone += "9"
            newPhone += number

            return newPhone
        })

        return phones
    }

    const findContact = async (identity) => {
        try {
            startLoading()

            identity = identity?.split("(").join("").split(")").join("").split(" ").join("").split("-").join("")

            let contact;

            let items = [
                identity,
                ...(
                    identity?.split("@")?.length == 1 ?
                        generatePossiblePhones(identity).map(item => `${item}@wa.gw.msging.net`) :
                        []
                )
            ]

            for (let item of items) {
                try {
                    const { response, ...rest } = await IframeMessageProxy.sendMessage({
                        action: IMPContainer.Actions.SEND_COMMAND,
                        content: {
                            command: {
                                method: IMPContainer.CommandMethods.GET,
                                uri: `/contacts/${item?.trim()}`
                            }
                        }
                    });

                    if (response?.identity) {
                        contact = response
                        break
                    }
                } catch (error) {
                    // console.error(error)
                }
            }

            return contact
        } catch (error) {
            // console.error(error)
        } finally {
            stopLoading()
        }
    }

    const closeModal = () => setOpenModal(undefined)

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={t('title.homePage')}
                icon={PAGE_ICON}
                openSidebar={async (id) => {
                    const acc = document.getElementById(id);
                    acc.isOpen = true;
                }}
            />

            <Sidebar
                findContact={findContact}
                changeBlocklistStatusContact={changeBlocklistStatusContact}
            />

            {error ?
                <ErrorLoad {...error} /> :
                <div className="flex" style={{ gap: 20 }}>
                    <div className="w-100 m0 mb3 mb4-l mt4">
                        {/* 
                        {isLoading &&
                            <div style={{ textAlign: "center" }}>
                                <h1 style={{ color: "#546e7a", fontSize: "1.5rem", fontWeight: 400 }}>Carregando seus contatos</h1>
                                <h1 style={{ color: "#546e7a", fontSize: "1.2rem", fontWeight: 400 }}>Isto pode demorar um pouco</h1>

                                <hr />
                            </div>
                        } */}

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <h1 style={{ color: "#546e7a", fontSize: "1.5rem", fontWeight: 400 }}>Contatos Bloqueados</h1>
                            {isLoading &&
                                <div>
                                    <bds-loading-spinner size="small" color="light" />
                                </div>
                            }
                        </div>

                        <ContactList
                            contacts={blockedContacts}
                            mergeContactExtras={mergeContactExtras}
                            changeBlocklistStatusContact={changeBlocklistStatusContact}
                            buttons={true}
                        />
                    </div>
                </div>
            }

            {/* <bds-alert open="true">
                <bds-alert-header variant="warning" icon="warning">
                    Atenção!
                </bds-alert-header>
                <bds-alert-body>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. At corporis eligendi cumque ratione 	  nulla a quos error!
                </bds-alert-body>
                <bds-alert-actions>
                    <bds-button variant="secondary" bold="true">Cancelar</bds-button>
                    <bds-button variant="secondary" bold="true" onClick={() => alert("kk")}>Confirmar</bds-button>
                </bds-alert-actions>
            </bds-alert> */}

            < Copyright />
        </div >
    );
};

Home.propTypes = {};

export default Home;

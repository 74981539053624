import React, { createContext, useContext, useState, useEffect } from 'react';
import { getApplicationDataAsync } from '../services/application-service';
import { getLoggedUserAsync } from '../services/user-service';
import axios from 'axios';
// import { btoa } from '../services/utils';

// const API_URL = "http://localhost:3333/v1"
const API_URL = "https://plugins.blip.sharkdev.com.br/v1"
const API_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbHVnaW5faWQiOiI2NGU2MmQ4MmQ3MDY3ZDBkZjcwNWJmMWIiLCJpYXQiOjE2OTI4MDY1NzF9._UPj4HHL3hqrnJz5E1fNYXLYZx8fU25o5D0MIpxU0pI"

const SharkPluginsContext = createContext(undefined);

export function SharkPluginsProvider({
    children
}) {
    const [appInfo, setAppInfo] = useState()
    const [user, setUser] = useState()
    const [language, setLanguage] = useState()

    useEffect(() => {
        getAppDataAsync(true);
        // start()
    }, [])

    // useEffect(() => {
    //     if (appInfo && user)
    //         start()
    // }, [appInfo, user]);

    const getAppDataAsync = async (first = false) => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);
        // console.log(app)

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);

        // setLanguage(i18n.language);

        if (first)
            start(app, loggedUser)

        return { appInfo: app, user: loggedUser }
    }

    const getAppData = async () => {
        if (appInfo && user)
            return { appInfo, user }
        return getAppDataAsync()
    }

    const start = async (app, loggedUser) => {
        let config = {
            name: app.name,
            plan: app.plan,
            shortName: app.shortName,
            tenantId: app.tenantId,
            user: loggedUser.email
        }

        let instance_token = btoa(JSON.stringify({ name: config.name, plan: config.plan, shortName: config.shortName, tenantId: config.tenantId, user: config.user }))

        await axios.post(`${API_URL}/instances`, {}, { headers: { instance_token, Authorization: API_TOKEN } })

        sendAction({
            type: "event",
            action: "access",
            value: 1
        }, config)
    }

    // { type, action, reference, value, user }
    const sendAction = async (props, config = {}) => {
        try {
            let { name: nm, plan, shortName, tenantId, user: usr } = config

            if (!nm) nm = appInfo?.name
            if (!plan) plan = appInfo?.plan
            if (!shortName) shortName = appInfo?.shortName
            if (!tenantId) tenantId = appInfo?.tenantId
            if (!user) usr = user?.email

            if (!tenantId) {
                let { appInfo, user } = await getAppData()

                if (!nm) nm = appInfo?.name
                if (!plan) plan = appInfo?.plan
                if (!shortName) shortName = appInfo?.shortName
                if (!tenantId) tenantId = appInfo?.tenantId
                if (!user) usr = user?.email
            }

            let instance_token = btoa(JSON.stringify({ name: nm, plan, shortName, tenantId, user: usr }))

            let json = {
                // user: user.email,
                ...props
            }

            await axios.post(`${API_URL}/actions`, json, { headers: { instance_token, Authorization: API_TOKEN } })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <SharkPluginsContext.Provider
            value={{
                sendAction
            }}
        >
            {children}
        </SharkPluginsContext.Provider>
    );
}

export function useSharkPlugins() {
    const context = useContext(SharkPluginsContext);
    if (context === undefined) {
        throw new Error('useSharkPlugins must be used within an SharkPluginsProvider');
    }
    return context;
}

import React from 'react'

export default function ErrorLoad({
    messageHTML,
    message
}) {
    return (
        <div style={{ padding: "40px 0" }}>
            <h1 style={{ textAlign: "center" }}>Ooops!</h1>

            {messageHTML ?
                <p style={{ textAlign: "center", fontSize: 24 }} dangerouslySetInnerHTML={{ __html: messageHTML }} /> :
                <p style={{ textAlign: "center", fontSize: 24 }}>{message}</p>
            }
        </div>
    )
}
import React, { useState } from 'react';
import Input from '../Input/Input';
import CardContact from '../cards/CardContact';
import { showToast } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';

export default function Sidebar({
    findContact,
    changeBlocklistStatusContact
}) {
    const id = "sb-left"

    const [inputIdentity, setInputIdentity] = useState()
    const [contact, setContact] = useState()

    const handleSubmit = async () => {
        try {
            let contactReturned = await findContact(inputIdentity)
            // console.log("handleSubmit", contactReturned)

            if (contactReturned)
                setContact(contactReturned)
            else {
                showToast({
                    type: BlipPortalToastTypes.DANGER,
                    message: "Contato não encontrado"//t('success.loaded')
                });
                setContact(null)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const block = async () => {
        try {
            await changeBlocklistStatusContact(contact, true)

            const acc = document.getElementById(id);
            acc.isOpen = false;
            setContact(undefined)
            setInputIdentity(undefined)
        } catch (error) {

        }
    }

    const cancel = async () => {
        setContact(null)
        const acc = document.getElementById(id);
        acc.isOpen = false;
    }

    return (
        <bds-sidebar id={id} sidebar-position="left">
            <div slot="header">
                <bds-typo bold="bold" variant="fs-20" line-height="double" margin="false">
                    Bloquear um contato
                </bds-typo>
            </div>
            <div slot="body">

                <Input
                    name="identity"
                    label="ID do Usuário"
                    placeholder="ID do Usuário"
                    value={inputIdentity}
                    onChange={e => setInputIdentity(e.target.value)}
                />

                <div className="mt2">
                    <bds-button variant="primary" disabled={!inputIdentity} onClick={handleSubmit}>Buscar</bds-button>
                </div>


                {contact &&
                    <div>
                        <br />
                        <CardContact
                            {...contact}
                            direction='column'
                        />

                        {contact?.extras?.blocklistStatus == "userBlocked" &&
                            <div className="pb3">
                                <bds-typo bold="extra-bold" variant="fs-14">Este contato já está bloqueado</bds-typo>
                            </div>
                        }

                        <div className="flex flex-row" style={{ gap: 10 }}>
                            <bds-button variant="ghost" onClick={cancel}>Cancelar</bds-button>
                            {(!contact?.extras?.blocklistStatus || contact?.extras?.blocklistStatus != "userBlocked") && <bds-button variant="delete" icon="lock" onClick={block}>Bloquear</bds-button>}
                        </div>
                    </div>
                }

            </div>
            <div slot="footer">
                <bds-button variant="delete">Cancelar</bds-button>
            </div>
        </bds-sidebar>
    )
}
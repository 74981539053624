import React, { useState } from 'react'
import Card from '../../Card/Card'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../../Button';
import Input from '../../Input/Input';

export default function CardContact({
    contact,
    name,
    lastMessageDate,
    source,
    direction = "row",
    photoUri,
    extras,
    buttons = false,
    mergeContactExtras,
    changeBlocklistStatusContact
}) {
    const { t, i18n } = useTranslation();

    const [editOpen, setEditOpen] = useState(false)

    const [inputDescription, setInputDescription] = useState(contact?.extras?.blocklistDescription || "")

    const sourceToChannel = (source) => ({
        "0mn.io": "Blip Chat",
        "tangram.com.br": "Tangram(SMS)",
        "take.io": "Take.IO(SMS)",
        "messenger.gw.msging.net": "Messenger",
        "instagram.gw.msging.net": "Instagram",
        "wa.gw.msging.net": "WhatsApp",
        "abs.gw.msging.net": "AzureBotService(Microsoft Teams)",
        "businessmessages.gw.msging.net": "Google Business Messages",
        "skype.gw.msging.net": "Skype",
        "telegram.gw.msging.net": "Telegram",
        "workplace.gw.msging.net": "Workplace",
        "mailgun.gw.msging.net": "Email"
    }[source] || source)

    return (
        <Card className="card-contact min-h-18 mb3">
            {/*photoUri*/}
            {/* <div>
                {photoUri}
                <bds-avatar thumbnail={photoUri}></bds-avatar>
            </div> */}
            <div className={`flex flex-${direction} ${direction == "row" ? "items-center" : ""}`} >
                <div className={direction == "row" ? "pr5 pl3" : ""}>
                    <p className="bp-c-city bp-fs-6 mv1">{name}</p>
                    <p className="bp-c-cloud bp-fs-7 mv1">Última interação: {moment(lastMessageDate).format("DD/MM/YYYY, HH:mm")}</p>
                </div>
                <div style={{ width: direction == "row" ? 1 : "100%", minHeight: direction == "row" ? 40 : 1, backgroundColor: "#e3e9ec" }}></div>
                <div className={`card-section ${direction == "row" ? "pl4 pr4" : "pt1"}`}>
                    <p className="bp-c-cloud bp-fs-7 mv1">Canal</p>
                    <p className="bp-c-city bp-fs-6 mv1">{sourceToChannel(source)}</p>
                </div>
                {(direction == "row" && ((extras?.blocklistDescription && extras?.blocklistDescription?.trim != "") || editOpen)) && <>
                    <div style={{ width: direction == "row" ? 1 : "100%", minHeight: direction == "row" ? 40 : 1, backgroundColor: "#e3e9ec" }}></div>
                    {editOpen ?
                        <div className="pl4 pr4" style={{ flex: 1 }}>
                            <Input
                                name="description"
                                label="Descrição"
                                placeholder="Descrição"
                                value={inputDescription}
                                onChange={e => setInputDescription(e.target.value)}
                            />
                        </div> :
                        <div className={`card-section ${direction == "row" ? "pl4 pr4" : "pt1"}`}>
                            <p className="bp-c-cloud bp-fs-7 mv1">Observação</p>
                            <p className="bp-c-city bp-fs-6 mv1">{extras.blocklistDescription}</p>
                        </div>
                    }
                </>}
                {buttons &&
                    <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center", gap: 10, ...(editOpen ? {} : { flex: 1 }) }}>
                        {editOpen ?
                            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: 10 }}>
                                <Button
                                    icon="close"
                                    variant="ghost"
                                    onClick={async () => {
                                        setEditOpen(false)
                                    }}
                                />

                                <Button
                                    icon="checkball"
                                    variant="ghost"
                                    onClick={async () => {
                                        let extras = { blocklistDescription: inputDescription }
                                        await mergeContactExtras({ ...contact, extras: { ...contact?.extras, ...extras } }, extras)
                                        setEditOpen(false)
                                    }}
                                />
                            </div> :
                            <div className="hover">
                                <Button
                                    icon="notes"
                                    variant="ghost"
                                    onClick={() => setEditOpen(true)}
                                />
                            </div>
                        }
                        {!editOpen &&
                            <div>
                                <Button
                                    icon="unlock"
                                    variant="ghost"
                                    onClick={() => changeBlocklistStatusContact(contact, false)}
                                    dataTestId="repository-link"
                                />
                            </div>
                        }
                    </div>
                }
            </div>
        </Card >
    )
}
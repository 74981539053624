"use client";

import { SharkPluginsProvider } from "./SharkPluginsProvider";

export default function Providers({
    children
}) {
    return (
        <SharkPluginsProvider>
            {children}
        </SharkPluginsProvider>
    )
}